import React, { useState, useEffect, useRef } from "react";
import { useIdentityContext } from "react-netlify-identity";
import Layout from "components/Layout";
import {
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Typography,
  Avatar,
  ListItemSecondaryAction,
  Divider,
  IconButton,
  Grid,
  Tooltip,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button,
  MenuItem,
  CircularProgress,
  LinearProgress,
  Link,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import {
  AccountCircle as AccountCircleIcon,
  Delete as DeleteIcon,
  Lock as LockIcon,
} from "@material-ui/icons";

import { users as usersData } from "assets/users";

export interface User {
  id: string;
  email: string;
  user_metadata: {
    full_name?: string;
  };
  app_metadata: {
    roles?: string[];
  };
}

export const UsersPage = () => {
  const { user } = useIdentityContext();
  const [currentUser, setCurrentUser] = useState<User>();
  console.log("AdminUsersPage -> currentUser", currentUser);
  const [users, setUsers] = useState<any[]>([]);
  const [formState, setFormState] = useState("idle");
  console.log("AdminUsersPage -> formState", formState);

  useEffect(() => {
    fetchUsers(user!.token.access_token);
  }, [user]);

  const fetchUsers = (user_token: string) => {
    setFormState("fetching_users");

    // Fetch real thing on LIVE
    process.env.NODE_ENV === "production" &&
      fetch("/.netlify/functions/list-users", {
        headers: {
          Authorization: `Bearer ${user_token}`,
        },
      })
        .then((res) => res.json())
        .then((result) => setUsers(result.data.users))
        .catch((err) => {
          console.log(err);
          setFormState("failed.fetching_users");
        })
        .finally(() => setFormState("idle"));

    // Local data in development
    if (process.env.NODE_ENV !== "production") {
      setUsers(usersData.data.users);
      setFormState("idle");
    }
  };

  // Handle reset password
  const passwordInput = useRef<HTMLInputElement>(null!);
  const [openPasswordReset, setOpenPasswordReset] = useState(false);
  const handleClickOpenResetPassword = (
    e: React.SyntheticEvent,
    user: User
  ) => {
    setCurrentUser(user);
    setOpenPasswordReset(true);
    setFormState("idle");
  };
  const handleCloseResetPassword = () => {
    setOpenPasswordReset(false);
    setFormState("idle");
  };
  const handleClickResetPassword = (e: React.SyntheticEvent): void => {
    e.preventDefault();

    const password = passwordInput.current.value;

    setFormState("requesting");
    fetch("/.netlify/functions/update-user", {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${user?.token.access_token}`,
      },
      body: JSON.stringify({
        userID: currentUser?.id,
        attributes: { password },
      }),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.statusText);
        }
        return res.json();
      })
      .then((result) => {
        setFormState("success");
        console.log(result);
      })
      .catch((err) => {
        setFormState("failed");
        console.log(err);
      })
      .finally(() => {
        passwordInput.current.value = "";
      });
  };
  const renderPasswordResetDialog = () => {
    return (
      <Dialog open={openPasswordReset} onClose={handleCloseResetPassword}>
        {formState === "success" && (
          <Alert severity="success" style={{ marginBottom: `15px` }}>
            Successfully set new password for user!
          </Alert>
        )}
        {formState === "failed" && (
          <Alert severity="error" style={{ marginBottom: `15px` }}>
            Ooops... Unable to set new password for user!
          </Alert>
        )}
        <DialogTitle>Reset Password for {currentUser?.email}?</DialogTitle>
        <form onSubmit={handleClickResetPassword}>
          <DialogContent>
            <DialogContentText>
              Please enter new password below for user.
            </DialogContentText>
            <TextField
              autoFocus
              fullWidth
              required
              label="New Password"
              type="password"
              inputRef={passwordInput}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseResetPassword}>Cancel</Button>
            <Button
              type="submit"
              color="primary"
              disabled={formState !== "idle"}
            >
              {formState === "requesting" && <CircularProgress size={20} />}
              {formState === "requesting" ? " Submitting..." : "Reset Password"}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  };

  // Handle update profile and role
  const nameInput = useRef<HTMLInputElement>(null!);
  const roleInput = useRef<HTMLInputElement>(null!);
  const [openUpdateUserDialog, setOpenUpdateUserDialog] = useState(false);
  const handleClickOpenUpdateUserDialog = (
    e: React.BaseSyntheticEvent,
    user: User
  ) => {
    setOpenUpdateUserDialog(true);
    setFormState("idle");
    setCurrentUser(user);
  };
  const handleCloseUpdateUserDialog = () => setOpenUpdateUserDialog(false);
  const handleClickUpdateUser = (e: React.SyntheticEvent) => {
    e.preventDefault();

    const name = nameInput.current.value;
    const role = roleInput.current.value;

    let attributes = {
      user_metadata: {
        full_name: name,
      },
    };
    if (role === "admin") {
      attributes = {
        ...attributes,
        ...{
          app_metadata: {
            roles: currentUser?.app_metadata?.roles
              ? [...currentUser?.app_metadata?.roles, role]
              : [role],
          },
        },
      };
    } else {
      attributes = {
        ...attributes,
        ...{
          app_metadata: {
            roles: currentUser?.app_metadata?.roles
              ? currentUser?.app_metadata?.roles.filter(
                  (role) => role !== "admin"
                )
              : [],
          },
        },
      };
    }

    setFormState("requesting");
    fetch("/.netlify/functions/update-user", {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${user?.token.access_token}`,
      },
      body: JSON.stringify({
        userID: currentUser?.id,
        attributes,
      }),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.statusText);
        }

        // Update users state
        setUsers((users) => {
          return users.map((user) => {
            if (user.id === currentUser?.id) {
              return { ...currentUser, ...attributes };
            }

            return user;
          });
        });

        return res.json();
      })
      .then((result) => {
        console.log(result);
        setFormState("success");
      })
      .catch((err) => {
        console.log(err);
        setFormState("failed");
      });
  };
  const renderUpateUserDialog = () => {
    return (
      <Dialog open={openUpdateUserDialog} onClose={handleCloseUpdateUserDialog}>
        {formState === "success" && (
          <Alert severity="success" style={{ marginBottom: `15px` }}>
            Successfully updated user!
          </Alert>
        )}
        {formState === "failed" && (
          <Alert severity="error" style={{ marginBottom: `15px` }}>
            Ooops... Unable to update user!
          </Alert>
        )}
        <form onSubmit={handleClickUpdateUser}>
          <DialogTitle>Update Account for {currentUser?.email}?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              You can update the user's name, and set role for user
            </DialogContentText>
            <TextField
              autoFocus
              fullWidth
              required
              label="Name"
              variant="outlined"
              inputRef={nameInput}
              defaultValue={currentUser?.user_metadata.full_name}
            />
            <br />
            <br />
            <TextField
              select
              fullWidth
              required
              label="Role"
              variant="outlined"
              inputRef={roleInput}
              defaultValue={
                (currentUser?.app_metadata.roles &&
                  currentUser?.app_metadata.roles.find(
                    (role) => role === "admin"
                  )) ||
                "regular"
              }
            >
              <MenuItem value="regular">Regular</MenuItem>
              <MenuItem value="admin">Admin</MenuItem>
            </TextField>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseUpdateUserDialog}>Cancel</Button>
            <Button type="submit" color="primary">
              {formState === "requesting" && <CircularProgress size={20} />}
              {formState === "requesting"
                ? "Updating User..."
                : "Confirm Update"}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  };

  // Handle delete user
  const [openDeleteUserDialog, setOpenDeleteUserDialog] = useState(false);
  const confirmDeleteInput = useRef<HTMLInputElement>(null!);
  const handleClickOpenDeleteUserDialog = (
    e: React.BaseSyntheticEvent,
    user: User
  ) => {
    setOpenDeleteUserDialog(true);
    setCurrentUser(user);
    setFormState("idle");
  };
  const handleCloseDeleteUserDialog = () => setOpenDeleteUserDialog(false);
  const handleClickDeleteUser = (e: React.SyntheticEvent) => {
    e.preventDefault();

    const confirmDelete = confirmDeleteInput.current.value;

    if (confirmDelete === currentUser?.email) {
      setFormState("requesting");
      fetch(`/.netlify/functions/delete-user/?userID=${currentUser?.id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${user?.token.access_token}`,
        },
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error(res.statusText);
          }
          return res;
        })
        .then((result) => {
          console.log(result);
          setFormState("success");
          setUsers((users) =>
            users.filter((user) => user.id !== currentUser?.id)
          );

          confirmDeleteInput.current.value = "";
        })
        .catch((err) => {
          setFormState("failed");
          console.log(err);
        });
    } else {
      setFormState("invalid");
    }
  };
  const renderDeleteUserDialog = () => {
    return (
      <Dialog open={openDeleteUserDialog} onClose={handleCloseDeleteUserDialog}>
        {formState === "success" && (
          <Alert severity="success" style={{ marginBottom: `15px` }}>
            Successfully deleted user!
          </Alert>
        )}
        {formState === "failed" && (
          <Alert severity="error" style={{ marginBottom: `15px` }}>
            Ooops... Unable to delete user!
          </Alert>
        )}
        {formState === "invalid" && (
          <Alert severity="error" style={{ marginBottom: `15px` }}>
            Just a sec cowboy... Email address entered is incorrect!
          </Alert>
        )}
        <form onSubmit={handleClickDeleteUser}>
          <DialogTitle>
            Are you sure to delete {currentUser?.email}?
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              This action is irreversible! All associated data of the said user
              will be removed.
            </DialogContentText>
            <TextField
              autoFocus
              fullWidth
              required
              disabled={formState !== "idle"}
              placeholder={currentUser?.email}
              label="Enter email address of user for deletion"
              inputRef={confirmDeleteInput}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDeleteUserDialog}>Cancel</Button>
            <Button
              type="submit"
              color="primary"
              disabled={formState !== "idle"}
            >
              {formState === "requesting" && <CircularProgress size={20} />}
              {formState === "requesting"
                ? "Deleting User..."
                : "Confirm Delete"}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  };

  return (
    <>
      <Layout>
        <Grid container justify="center" alignItems="center">
          <Grid item lg={8} md={8} sm={12} xs={12}>
            <Typography variant="h3">Manage Users</Typography>
            <Typography variant="subtitle1">
              Update profile name, reset user password, assign a role.
            </Typography>

            <br />
            {formState === "fetching_users" && <LinearProgress />}
            {formState === "failed.fetching_users" && (
              <Alert severity="error" style={{ marginBottom: `15px` }}>
                Ooops... Something went wrong fetching users.{" "}
                <Link
                  href="#"
                  onClick={() => fetchUsers(user!.token.access_token)}
                >
                  Click here to retry...
                </Link>
              </Alert>
            )}
            <br />
            <Paper elevation={3}>
              <List component="nav" aria-label="Users List">
                {users.map((user) => {
                  const {
                    app_metadata: { roles },
                    user_metadata: { full_name },
                    confirmed_at,
                  } = user;

                  return (
                    <React.Fragment key={user.id}>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar src="/broken-image.jpg">
                            {user.email[0].toUpperCase()}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={user.email}
                          secondary={`${full_name || "Name not set"}`}
                        />
                        <ListItemSecondaryAction>
                          {roles && roles.includes("admin") && (
                            <Chip
                              label="Admin"
                              color="primary"
                              style={{ marginLeft: `5px` }}
                            />
                          )}
                          {!confirmed_at && (
                            <Chip
                              label="Invited"
                              color="default"
                              style={{ marginLeft: `5px` }}
                            />
                          )}

                          <Tooltip
                            title={
                              !confirmed_at
                                ? "User must accept invitation first"
                                : "Reset User Password"
                            }
                          >
                            <span>
                              <IconButton
                                edge="end"
                                aria-label="delete"
                                onClick={(e) =>
                                  handleClickOpenResetPassword(e, user)
                                }
                                disabled={!confirmed_at}
                              >
                                <LockIcon />
                              </IconButton>
                            </span>
                          </Tooltip>
                          <Tooltip title="Update Profile">
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              onClick={(e) =>
                                handleClickOpenUpdateUserDialog(e, user)
                              }
                            >
                              <AccountCircleIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete User">
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              onClick={(e) =>
                                handleClickOpenDeleteUserDialog(e, user)
                              }
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </ListItemSecondaryAction>
                      </ListItem>
                      <Divider variant="inset" component="li" />
                    </React.Fragment>
                  );
                })}
              </List>
            </Paper>
          </Grid>
        </Grid>
      </Layout>
      {renderPasswordResetDialog()}
      {renderUpateUserDialog()}
      {renderDeleteUserDialog()}
    </>
  );
};
