import React, { useRef, useState } from "react";
import {
  Grid,
  TextField,
  Button,
  LinearProgress,
  Typography,
} from "@material-ui/core/";
import Alert from "@material-ui/lab/Alert";
import { useIdentityContext } from "react-netlify-identity";
import { Link, useHistory } from "react-router-dom";

// ref: https://github.com/sw-yx/react-netlify-identity/blob/master/examples/example-react-router/src/views/LogIn/LogIn.tsx
export const LoginForm = () => {
  const { loginUser } = useIdentityContext();
  const emailInput = useRef<HTMLInputElement>(null!);
  const passwordInput = useRef<HTMLInputElement>(null!);
  const history = useHistory();
  const [formState, setFormState] = useState("idle");

  const handleLogin = (event: React.SyntheticEvent<HTMLFormElement>): void => {
    event.preventDefault();

    const email = emailInput.current.value;
    const password = passwordInput.current.value;

    setFormState("requesting");
    loginUser(email, password, true)
      .then(() => {
        history.push("/home");
      })
      .catch((error) => {
        console.log(error);
        setFormState("rejected");
      });
  };

  return (
    <>
      <Typography variant="h4" gutterBottom>
        Login
      </Typography>

      {formState === "requesting" && <LinearProgress />}
      {formState === "rejected" && (
        <Alert severity="error">Username or password is incorrect!</Alert>
      )}
      <br />
      <form onSubmit={handleLogin}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              id="Email"
              label="Email"
              placeholder="john@example.com"
              variant="outlined"
              inputRef={emailInput}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              type="password"
              id="Password"
              label="Password"
              variant="outlined"
              inputRef={passwordInput}
            />
          </Grid>
          <Grid
            container
            item
            xs={12}
            justify="space-between"
            alignItems="center"
          >
            <Link to="/reset">Forgot password?</Link>
            <Button
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              disabled={formState === "requesting"}
            >
              {formState === "requesting" ? "Signing in..." : "Sign in"}
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};
