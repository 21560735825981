import React, { useRef, useState, useEffect } from "react";
import {
  Typography,
  Grid,
  TextField,
  Button,
  LinearProgress,
} from "@material-ui/core/";
import Alert from "@material-ui/lab/Alert";
import { useIdentityContext } from "react-netlify-identity";
import { Link, useHistory } from "react-router-dom";

export const AcceptInviteForm = () => {
  const { push } = useHistory();
  const {
    param: { token },
  } = useIdentityContext();
  const passwordInput = useRef<HTMLInputElement>(null!);
  const passwordConfirmInput = useRef<HTMLInputElement>(null!);
  const [formState, setFormState] = useState("idle");

  // Redirect if token is not set as it's invalid
  useEffect(() => {
    if (!token) {
      console.error("Unable to verify invitation token!");
      push("/");
    }
  }, [push, token]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    const password = passwordInput.current.value;
    const passwordConfirm = passwordConfirmInput.current.value;

    if (password !== passwordConfirm) {
      setFormState("passwords_mismatch");
      return;
    }

    setFormState("requesting");
    fetch("/.netlify/functions/accept-invite", {
      method: "POST",
      body: JSON.stringify({
        token,
        password,
      }),
    })
      .then((res) => res.json())
      .then((result) => {
        setFormState("user_updated");
        console.log("Successfully set password for invited user!");
      })
      .catch((error) => {
        console.log(error);
        setFormState("unverified");
      })
      .finally(() => {
        passwordInput.current.value = "";
        passwordConfirmInput.current.value = "";
      });
  };

  const disabledFormStates = ["requesting", "user_updated", "unverified"];

  return (
    <>
      <Typography variant="h4" gutterBottom>
        Create Account
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        Let's set a password for your account.
      </Typography>
      <br />

      {formState === "requesting" && <LinearProgress />}
      {formState === "user_updated" && (
        <Alert severity="success">
          Successfully set user password for account.{" "}
          <Link to="/login">Click here to login.</Link>
        </Alert>
      )}
      {formState === "rejected" && (
        <Alert severity="error">Unable to set password for account!</Alert>
      )}
      {formState === "unverified" && (
        <Alert severity="error">
          Invitation link is no longer valid. Please contact administrator if
          you believe this is an error!
        </Alert>
      )}
      {formState === "passwords_mismatch" && (
        <Alert severity="error">
          Password and Password Confirm does not match!
        </Alert>
      )}
      <br />
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              type="password"
              label="Password"
              variant="outlined"
              inputRef={passwordInput}
              disabled={disabledFormStates.includes(formState)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              type="password"
              label="Password Confirm"
              variant="outlined"
              inputRef={passwordConfirmInput}
              disabled={disabledFormStates.includes(formState)}
            />
          </Grid>
          <Grid
            container
            item
            xs={12}
            alignItems="center"
            justify="space-between"
          >
            <Button
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              disabled={disabledFormStates.includes(formState)}
            >
              {formState === "requesting"
                ? "Setting password"
                : "Confirm Password"}
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};
