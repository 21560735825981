import React, { useEffect } from "react";
import Layout from "components/Layout";
import { Button, Grid } from "@material-ui/core";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { useIdentityContext } from "react-netlify-identity";

export const IndexPage = () => {
  const { user } = useIdentityContext();
  const { push } = useHistory();

  useEffect(() => {
    if (user) {
      push("/home");
    }
  }, [user, push]);

  return (
    <Layout>
      <Grid container justify="center" alignItems="center">
        <Grid item lg={6} md={6} sm={8} xs={12} style={{ textAlign: `center` }}>
          <br />
          <Button
            variant="contained"
            color="primary"
            component={RouterLink}
            to="/login"
          >
            Click here to login
          </Button>
        </Grid>
      </Grid>
    </Layout>
  );
};
