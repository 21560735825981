import React from "react";
import Layout from "components/Layout";
import { Grid } from "@material-ui/core/";
import { AcceptInviteForm } from "components/Auth";

export const AcceptInvitePage = () => {
  return (
    <Layout>
      <Grid container justify="center" alignItems="center">
        <Grid item lg={6} md={6} sm={8} xs={12}>
          <AcceptInviteForm />
        </Grid>
      </Grid>
    </Layout>
  );
};
