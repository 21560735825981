import React from "react";
import Layout from "components/Layout/Layout";
import { Grid } from "@material-ui/core/";
import { ResetForm } from "components/Auth/ResetForm";

export const ResetPage = () => {
  return (
    <Layout>
      <Grid container justify="center" alignItems="center">
        <Grid item lg={6} md={6} sm={8} xs={12}>
          <ResetForm />
        </Grid>
      </Grid>
    </Layout>
  );
};
