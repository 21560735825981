import React, { useRef, FormEvent, useState } from "react";
import Layout from "components/Layout";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Grid,
  TextField,
  Icon,
  Button,
  MenuItem,
  Typography,
  Box,
  LinearProgress,
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    group: {
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(5),
    },
    form: {
      marginBottom: theme.spacing(5),
    },
  })
);

export const RequestFormPage = () => {
  const classes = useStyles();

  const emailInput = useRef<HTMLInputElement>(null!);
  const [formState, setFormState] = useState("idle");

  const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    const email = emailInput.current.value;

    setFormState("requesting");

    // @todo: Save form submission via WebriQ Forms?
    // Send email
    fetch(`/.netlify/functions/email-request-form`, {
      method: "post",
      body: JSON.stringify({ email }),
    })
      .then((res) => res.json())
      .then(() => {
        setFormState("fulfilled");
      })
      .catch((err) => {
        setFormState("rejected");
      });
  };

  return (
    <Layout>
      <Typography variant="h3">Sample WebriQ Forms Integration</Typography>
      <Typography variant="subtitle1">
        An email with attachment will be sent to your inbox for you to complete
        by filling out the necessary details, afterwards you reply back.
      </Typography>
      <br />
      {formState === "requesting" && <LinearProgress />}
      {formState === "fulfilled" && (
        <Alert severity="success">Successfully sent form data! </Alert>
      )}
      {formState === "rejected" && (
        <Alert severity="error">
          Something went wrong submitting form data!{" "}
          <a href={`${window.location.href}`}>Please try again.</a>
        </Alert>
      )}
      <div className={classes.root}>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Box className={classes.group}>
            <Typography variant="h5" gutterBottom>
              Service
            </Typography>
            <Grid container className={classes.root} spacing={2}>
              <Grid item xs={12} md={12} sm={12}>
                <TextField
                  select
                  id="service"
                  required
                  fullWidth
                  label="Select a service"
                  variant="outlined"
                >
                  <MenuItem value="WebriQ Mad Maintenance">
                    WebriQ Mad Maintenance
                  </MenuItem>
                  <MenuItem value="WebriQ Mad">WebriQ Mad</MenuItem>
                  <MenuItem value="WebriQ Madder">WebriQ Madder</MenuItem>
                  <MenuItem value="WebriQ Maddest">WebriQ Maddest</MenuItem>
                </TextField>
              </Grid>
            </Grid>
          </Box>

          <Box className={classes.group}>
            <Typography variant="h5" gutterBottom>
              Contact Information
            </Typography>
            <Grid container className={classes.root} spacing={2}>
              <Grid item xs={12} md={4} sm={12}>
                <TextField
                  required
                  fullWidth
                  label="Email"
                  variant="outlined"
                  type="email"
                  inputRef={emailInput}
                />
              </Grid>
              <Grid item xs={12} md={4} sm={12}>
                <TextField
                  required
                  fullWidth
                  label="Contact Number"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} md={4} sm={12}>
                <TextField required fullWidth label="Name" variant="outlined" />
              </Grid>
            </Grid>
          </Box>

          <Grid
            container
            item
            className={classes.root}
            spacing={2}
            justify="space-between"
            alignItems="center"
          >
            <RouterLink to="/home">← Go back</RouterLink>
            <Button
              variant="contained"
              color="primary"
              size="large"
              startIcon={<Icon>send</Icon>}
              type="submit"
            >
              Submit Request
            </Button>
          </Grid>
        </form>
      </div>
    </Layout>
  );
};
