import React, { useState } from "react";
import Layout from "components/Layout";
import { InviteFormDialog } from "pages/Users";
import { Grid, Button, Link } from "@material-ui/core/";
import Alert from "@material-ui/lab/Alert";
import { useIdentityContext } from "react-netlify-identity";
import { Link as RouterLink } from "react-router-dom";

export const HomePage = () => {
  const { user, isConfirmedUser, logoutUser } = useIdentityContext();
  console.log("HomePage -> user", user);

  const [formState, setFormState] = useState("idle");

  const handleLogout = () => {
    setFormState("requesting");
    logoutUser();
  };

  return (
    <Layout>
      <Grid container justify="center">
        <Grid item lg={12} md={12} sm={8} xs={12}>
          <h1>Hello {user && user.email}</h1>
          {!isConfirmedUser && (
            <Alert severity="warning">
              Please check your inbox to verify account.
            </Alert>
          )}

          <InviteFormDialog />

          <p>
            <Link to={`/request`} component={RouterLink}>
              Create service order request here...
            </Link>
          </p>

          <br />
          <br />
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleLogout()}
            disabled={formState === "requesting"}
          >
            {formState === "requesting" ? "Logging out..." : "Logout"}
          </Button>
        </Grid>
      </Grid>
    </Layout>
  );
};
