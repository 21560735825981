import React from "react";
import Layout from "components/Layout/Layout";
import { RecoverForm } from "components/Auth";
import { Grid } from "@material-ui/core/";

export const RecoverPage = () => {
  return (
    <Layout>
      <Grid container justify="center" alignItems="center">
        <Grid item lg={6} md={6} sm={8} xs={12}>
          <RecoverForm />
        </Grid>
      </Grid>
    </Layout>
  );
};
