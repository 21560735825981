import { User } from "react-netlify-identity";

/**
 * Checks if user is admin
 *
 * For this to work with Netlify functions, see https://github.com/netlify/netlify-lambda/issues/133#issuecomment-552255479
 *
 * @param user User
 */
export const isAdmin = (user: User) => {
  if (!user) {
    return false;
  }

  return (
    !!user.app_metadata.roles &&
    user.app_metadata.roles.find((role: string) => role === "admin")
  );
};
