import React, { useRef, useState } from "react";
import Layout from "components/Layout";
import {
  Typography,
  Grid,
  TextField,
  Button,
  LinearProgress,
} from "@material-ui/core/";
import Alert from "@material-ui/lab/Alert";
import { useIdentityContext } from "react-netlify-identity";
import { Link, useHistory } from "react-router-dom";

export const SignupForm = () => {
  const { signupUser, loginUser } = useIdentityContext();
  const emailInput = useRef<HTMLInputElement>(null!);
  const passwordInput = useRef<HTMLInputElement>(null!);
  const history = useHistory();
  const [formState, setFormState] = useState("idle");

  const handleLogin = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    const email = emailInput.current.value;
    const password = passwordInput.current.value;

    setFormState("requesting");
    signupUser(email, password, {})
      .then(() => {
        loginUser(email, password, true);
        history.push("/home");
      })
      .catch((error) => {
        console.log(error);
        setFormState("rejected");
      });
  };

  return (
    <Layout>
      <Grid container justify="center" alignItems="center">
        <Grid item lg={6} md={6} sm={8} xs={12}>
          <Typography variant="h4" gutterBottom>
            Signup
          </Typography>

          {formState === "requesting" && <LinearProgress />}
          {formState === "rejected" && (
            <Alert severity="error">Unable to signup user!</Alert>
          )}
          <br />
          <form onSubmit={handleLogin}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  label="Email"
                  variant="outlined"
                  inputRef={emailInput}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  type="password"
                  label="Password"
                  variant="outlined"
                  inputRef={passwordInput}
                />
              </Grid>
              <Grid
                container
                item
                xs={12}
                justify="space-between"
                alignItems="center"
              >
                <Link to="/login">Login</Link>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  type="submit"
                  disabled={formState === "requesting"}
                >
                  {formState === "requesting" ? "Submitting..." : "Signup"}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Layout>
  );
};
