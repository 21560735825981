import React, { useRef, useState } from "react";
import Layout from "components/Layout/Layout";
import {
  Typography,
  Grid,
  TextField,
  Button,
  LinearProgress,
} from "@material-ui/core/";
import Alert from "@material-ui/lab/Alert";
import { useIdentityContext } from "react-netlify-identity";
import { Link } from "react-router-dom";

export const ResetForm = () => {
  const { requestPasswordRecovery } = useIdentityContext();
  const emailInput = useRef<HTMLInputElement>(null!);
  const [formState, setFormState] = useState("idle");

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    const email = emailInput.current.value;

    setFormState("requesting");
    requestPasswordRecovery(email)
      .then(() => {
        setFormState("fulfilled");
      })
      .catch((error) => {
        setFormState("rejected");
      })
      .finally(() => {
        emailInput.current.value = "";
      });
  };

  return (
    <Layout>
      <Grid container justify="center" alignItems="center">
        <Grid item lg={6} md={6} sm={8} xs={12}>
          <Typography variant="h4" gutterBottom>
            Forgot Password
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            We'll send an email to your inbox to reset your account's password.
          </Typography>
          <br />

          {formState === "requesting" && <LinearProgress />}
          {formState === "fulfilled" && (
            <Alert severity="success">
              Please check your inbox for password reset instructions!
            </Alert>
          )}
          {formState === "rejected" && (
            <Alert severity="error">
              Unable to find user with email address!
            </Alert>
          )}
          <br />
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  label="Email"
                  variant="outlined"
                  placeholder="email@example.com"
                  inputRef={emailInput}
                />
              </Grid>
              <Grid
                container
                item
                xs={12}
                alignItems="center"
                justify="space-between"
              >
                <Link to="/login">Back to Login</Link>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  type="submit"
                  disabled={formState === "requesting"}
                >
                  Reset Password
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Layout>
  );
};
