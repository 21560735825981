import React, { useRef, useState, useEffect } from "react";
import {
  Typography,
  Grid,
  TextField,
  Button,
  LinearProgress,
} from "@material-ui/core/";
import Alert from "@material-ui/lab/Alert";
import { useIdentityContext } from "react-netlify-identity";
import { Link } from "react-router-dom";

export const RecoverForm = () => {
  const { user, recoverAccount, updateUser } = useIdentityContext();
  const passwordInput = useRef<HTMLInputElement>(null!);
  const passwordConfirmInput = useRef<HTMLInputElement>(null!);
  const [formState, setFormState] = useState("idle");

  // Verify token as soon as possible
  useEffect(() => {
    recoverAccount(true);
  }, [recoverAccount]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    const password = passwordInput.current.value;
    const passwordConfirm = passwordConfirmInput.current.value;

    if (password !== passwordConfirm) {
      setFormState("passwords_mismatch");
      return;
    }

    setFormState("requesting");
    updateUser({ password })
      .then((updatedUser) => {
        setFormState("user_updated");
        console.log("Updated user %s", updatedUser);
      })
      .catch((error) => {
        console.log("Failed to update user: %o", error);
        setFormState("unverified");
      });
  };

  const disabledFormStates = ["requesting", "user_updated", "unverified"];

  return (
    <>
      <Typography variant="h4" gutterBottom>
        Recover Account
      </Typography>

      {formState === "requesting" && <LinearProgress />}
      {formState === "user_updated" && (
        <Alert severity="success">
          Successfully recovered account.{" "}
          <Link to="/home">Click here to go to homepage.</Link>
        </Alert>
      )}
      {formState === "rejected" && (
        <Alert severity="error">Unable to recover account!</Alert>
      )}
      {formState === "unverified" && (
        <Alert severity="error">
          Recovery link is no longer valid!{" "}
          <Link to="/reset">Click here to request a new one.</Link>
        </Alert>
      )}
      {formState === "passwords_mismatch" && (
        <Alert severity="error">
          Password and Password Confirm does not match!
        </Alert>
      )}
      <br />
      {user && (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                type="password"
                label="Password"
                variant="outlined"
                inputRef={passwordInput}
                disabled={disabledFormStates.includes(formState)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                type="password"
                label="Password Confirm"
                variant="outlined"
                inputRef={passwordConfirmInput}
                disabled={disabledFormStates.includes(formState)}
              />
            </Grid>
            <Grid
              container
              item
              xs={12}
              alignItems="center"
              justify="space-between"
            >
              <Link to="/login">Back to Login</Link>
              <Button
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                disabled={disabledFormStates.includes(formState)}
              >
                Update Password
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </>
  );
};
