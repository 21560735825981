import React from "react";
import Layout from "components/Layout";
import { Grid } from "@material-ui/core/";
import { SignupForm } from "components/Auth/SignupForm";

export const SignupPage = () => {
  return (
    <Layout>
      <Grid container justify="center" alignItems="center">
        <Grid item lg={6} md={6} sm={8} xs={12}>
          <SignupForm />
        </Grid>
      </Grid>
    </Layout>
  );
};
