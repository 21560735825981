import React, { useEffect } from "react";
import {
  BrowserRouter,
  Redirect,
  Switch,
  Route,
  useHistory,
  useLocation,
} from "react-router-dom";
import {
  IdentityContextProvider,
  useIdentityContext,
  User,
} from "react-netlify-identity";
import { isAdmin } from "utils/user";

import {
  IndexPage,
  HomePage,
  LoginPage,
  RequestFormPage,
  SignupPage,
  RecoverPage,
  ResetPage,
  AcceptInvitePage,
} from "pages";

import { UsersPage } from "pages/Users";

interface Props {
  component: React.FunctionComponent;
  exact?: boolean;
  path: string;
}

const PublicRoute: React.FunctionComponent<Props> = (props: Props) => {
  return <Route {...props} />;
};

const PrivateRoute: React.FunctionComponent<Props> = (props: Props) => {
  const { isLoggedIn } = useIdentityContext();

  return isLoggedIn ? <Route {...props} /> : <Redirect to="/login" />;
};

const AdminRoute: React.FunctionComponent<Props> = (props: Props) => {
  const { user, isLoggedIn } = useIdentityContext();

  return !isLoggedIn || !isAdmin(user!) ? (
    <Redirect to="/login" />
  ) : (
    <Route {...props} />
  );
};

const CatchIdentityTokenNullComponent = () => {
  const {
    param: { token, type },
  } = useIdentityContext();
  const { replace } = useHistory();
  const { pathname } = useLocation();

  useEffect(() => {
    // important to check for the current pathname here because else you land in a infinite loop
    if (token && type === "recovery" && pathname === "/") {
      replace(`/recover`, { token });
    }

    if (token && type === "invite" && pathname === "/") {
      replace(`/accept`, { token });
    }
  }, [replace, pathname, token, type]);

  return null;
};

const RefreshJWTNullComponent = () => {
  const { getFreshJWT } = useIdentityContext();

  let goTrueUser: User;
  try {
    goTrueUser = JSON.parse(localStorage.getItem("gotrue.user")!);
  } catch (err) {
    throw new Error("Unable to parse GoTrue User data from localStorage!");
  }

  // Check access token expiry
  let now = new Date().getTime();
  let expiresAt = now + 15 * 60000;

  if (goTrueUser && now > goTrueUser.token.expires_at) {
    // Token has expired, renew token now
    getFreshJWT()
      ?.then((result) => {
        console.log("Successfully refreshed token!");

        // Set new token when result is not equal to new token
        if (goTrueUser.token.access_token !== result) {
          localStorage.setItem(
            "gotrue.user",
            JSON.stringify({
              ...goTrueUser,
              ...{
                token: {
                  ...goTrueUser.token,
                  access_token: result,
                  expires_at: expiresAt,
                },
              },
            })
          );
          console.log("Updated token and expires_at!");
        }
      })
      .catch((err) => {
        console.log("Something went wrong refreshing token", err);

        // Logout if can't renew token
        localStorage.removeItem("gotrue.user");
      });
  }

  return null;
};

function App() {
  const url =
    process.env.IDENTITY_URL || "https://webriqpayments-demo.webriq.me";

  return (
    <>
      <IdentityContextProvider url={url}>
        <BrowserRouter>
          <CatchIdentityTokenNullComponent />
          <RefreshJWTNullComponent />
          <Switch>
            <AdminRoute exact path="/admin/users" component={UsersPage} />
            <Route exact path="/request" component={RequestFormPage} />
            <PublicRoute exact path="/login" component={LoginPage} />
            <PublicRoute exact path="/signup" component={SignupPage} />
            <PublicRoute exact path="/reset" component={ResetPage} />
            <PublicRoute exact path="/recover" component={RecoverPage} />
            <PublicRoute exact path="/accept" component={AcceptInvitePage} />
            <PrivateRoute exact path="/home" component={HomePage} />
            <Route path="/" component={IndexPage} />
          </Switch>
        </BrowserRouter>
      </IdentityContextProvider>
    </>
  );
}

export default App;
