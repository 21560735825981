import React, { useRef, useState } from "react";
import { useIdentityContext } from "react-netlify-identity";
import {
  Button,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  CircularProgress,
} from "@material-ui/core/";
import Alert from "@material-ui/lab/Alert";
import { Link as RouterLink } from "react-router-dom";

export const InviteFormDialog = () => {
  const { user } = useIdentityContext();
  const [formState, setFormState] = useState("idle");

  // Invite form dialog
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const emailInput = useRef<HTMLInputElement>(null!);

  const handleSubmitInvite = (e: React.SyntheticEvent): void => {
    e.preventDefault();

    const email = emailInput.current.value;

    setFormState("invitation_sending");
    fetch(`/.netlify/functions/invite-users`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${user?.token.access_token}`,
      },
      body: JSON.stringify({ email }),
    })
      .then((res) => res.json())
      .then((result) => {
        console.log("HomePage -> result", result);
        setFormState("invitation_success");

        emailInput.current.value = "";
      })
      .catch((err) => {
        console.log("HomePage -> err", err);
        setFormState("invitation_failed");
      })
      .finally(() => {
        setTimeout(function () {
          setFormState("idle");
        }, 2500);
      });
  };

  return (
    <>
      <p>
        <Link href="#" onClick={handleClickOpen}>
          Invite a new user
        </Link>
        <span> or </span>
        <Link to={`/admin/users`} component={RouterLink}>
          manage users...
        </Link>
      </p>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-invite-user"
        disableBackdropClick={formState !== "idle"}
        disableEscapeKeyDown={formState !== "idle"}
      >
        <DialogTitle id="form-title-invite-user">Invite User</DialogTitle>
        <form onSubmit={handleSubmitInvite}>
          <DialogContent>
            <DialogContentText>
              We'll send an email to the invited user to create an account.
            </DialogContentText>

            {formState === "invitation_success" && (
              <Alert severity="success">Successfully invited user!</Alert>
            )}
            {formState === "invitation_failed" && (
              <Alert severity="error">Unable to send user invitation!</Alert>
            )}

            <br />
            <TextField
              autoFocus
              fullWidth
              required
              variant="outlined"
              id="email"
              label="Email Address"
              type="email"
              inputRef={emailInput}
              disabled={formState !== "idle"}
            />
            <br />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} disabled={formState !== "idle"}>
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              disabled={formState !== "idle"}
            >
              {formState === "invitation_sending" && (
                <CircularProgress size={20} />
              )}
              <span style={{ marginLeft: `10px` }}>
                {formState === "invitation_sending"
                  ? "Sending..."
                  : "Send Invitation"}
              </span>
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};
