import React from "react";
import { Grid, Link, Typography } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { useStyles } from "./Header.styles";

export const Header = () => {
  const classes = useStyles();

  return (
    <>
      <Grid container justify="center" className={classes.container}>
        <Link to="/" component={RouterLink}>
          <Typography variant="h3" gutterBottom>
            WebriQ Payments Demo
          </Typography>
        </Link>
      </Grid>
    </>
  );
};

export default Header;
