import React, { ReactNode } from "react";
import { Container } from "@material-ui/core";
import { Helmet } from "react-helmet";

import Header from "components/Header/Header";

const Layout: React.FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <>
      <Helmet>
        <title>WebriQ Payments | Demo</title>
      </Helmet>
      <Container>
        <Header />
        {children}
      </Container>
    </>
  );
};

export default Layout;
